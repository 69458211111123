import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Header } from '../Header';
import { ContentItemBySlugResult } from '@guider-global/sanity-types';

type Props = ContentItemBySlugResult[0];

export const Video: FC<Props> = ({ content, metadata }) => {
  const videoUrl = content?.video ? content?.video : '';

  return (
    <Grid item xs={12} md={6} sx={{ mb: 8, mx: 'auto' }}>
      <Box
        component="iframe"
        src={videoUrl}
        sx={{
          height: '280px',
          width: '100%',
          borderRadius: 1,
          border: 'none',
          mb: 3,
        }}
        title={content?.title}
        allowFullScreen
      />
      <Header {...{ heading: content?.title, type: metadata?.type }} />
      <Typography variant="body1">{content?.excerpt}</Typography>
    </Grid>
  );
};
