import { FC } from 'react';
import { MdLink, MdLinkOff } from 'react-icons/md';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';

import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export interface IntegrationConnectedButtonProps {
  loading: boolean;
  connected: boolean;
  onClickDisconnect: () => Promise<void>;
  onClickConnect: () => Promise<void>;
}

const IntegrationConnectedButton: FC<IntegrationConnectedButtonProps> = ({
  loading,
  connected,
  onClickConnect,
  onClickDisconnect,
}) => {
  // Hooks
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const integrations = baseLanguage?.settings?.integrations;
  const disconnect = integrations?.disconnect_button_label;
  const connect = integrations?.connect_button_label;
  if (connected) {
    return (
      <IntegrationBaseButton
        color="error"
        onClick={onClickDisconnect}
        startIcon={<MdLinkOff />}
        loading={loading}
      >
        {disconnect}
      </IntegrationBaseButton>
    );
  }

  return (
    <IntegrationBaseButton
      color="info"
      onClick={onClickConnect}
      startIcon={<MdLink />}
      loading={loading}
    >
      {connect}
    </IntegrationBaseButton>
  );
};

export default IntegrationConnectedButton;

const IntegrationBaseButton: FC<LoadingButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  // Styles
  const isMobile = useMobileMediaQuery();

  return (
    <LoadingButton
      variant={isMobile ? 'outlined' : 'text'}
      sx={{
        display: 'flex',
        width: isMobile ? '100%' : 'max-content',
        minWidth: '140px',
        height: '30px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
