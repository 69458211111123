/* eslint-disable camelcase */
import React from 'react';
import { SanityContentLibrary } from '@guider-global/shared-types';
import { Box, Typography } from '@mui/material';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { getContentTypeIcon } from 'utils/getContentTypeIcon';

interface Props {
  heading: string | undefined;
  subheading?: string;
  type: SanityContentLibrary['metadata']['type'] | undefined;
}

export const Header: React.FC<Props> = ({ type, heading, subheading }) => {
  const { getBaseLanguage } = useSanityBaseLanguage({});

  const baseLanguage = getBaseLanguage();

  const content_types = baseLanguage.learning_hub?.content_types;

  const getContentTypeLabel = ({
    type,
  }: {
    type: SanityContentLibrary['metadata']['type'] | undefined;
  }) => {
    if (!content_types || !type) return;
    switch (type) {
      case 'pdf':
        return content_types['embedded_pdf'];
      case 'link':
        return content_types['external_link'];
      default:
        return content_types[type];
    }
  };

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <Box
        sx={{
          height: '6px',
          width: '162px',
          backgroundColor: 'info.light',
          borderRadius: '10px',
          my: 2,
        }}
      />
      <Typography variant="h4" component="h1">
        {heading}
      </Typography>
      {subheading && <Typography>{subheading}</Typography>}
      <Box
        sx={{
          color: 'text.disabled',
          gap: 1,
          display: 'flex',
          mt: 1,
          alignItems: 'center',
        }}
      >
        {getContentTypeIcon({ type })}
        <Typography variant="caption">
          {getContentTypeLabel({ type })}
        </Typography>
      </Box>
    </Box>
  );
};
